<template>
    <!-- 菜单页 -->
    <div id="app_index">
        <!-- 头 -->
        <el-row type="flex" align="middle" class="head_top" style="border-bottom: 3px solid #4cb926;">
            <el-col :span="14" style="text-align:left;padding-left:10px;">
                <span class="font_size3">管理平台-国际</span>
                <el-link type="primary" :underline="false" style="margin-left:10px;" @click="gn_jump()">国内</el-link>
            </el-col>
            <el-col :span="10"  style="text-align:right;padding-right:10px;color: #4cb926;font-weight: normal;">
                <span class="font_size3">Hi,{{qxt_username}}</span>
                <el-link @click="quit()" type="primary" :underline="false" style="margin-left:10px;color: #4cb926;">[退出]</el-link>
            </el-col>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <template v-for="item in showLevleList">
                    <el-row style="margin-top:30px;">
                        <el-col :span="8">
                            <template v-if="item.length>0">
                                <el-row @click.native="jump_url(item[0])" class="cursor" type="flex" align="middle" justify="center" style="display: inline-block;width:100%;text-align:center">
                                    <el-image  style="width:70px;height: 70px" :src="item[0].menu_img_url" fit="fill" ></el-image>
                                    <div style="text-align:center">{{item[0].menu_name}}</div>
                                </el-row>
                            </template>
                        </el-col>
                        <el-col :span="8">
                            <template v-if="item.length>1">
                                <el-row @click.native="jump_url(item[1])" class="cursor" type="flex" align="middle" justify="center" style="display: inline-block;width:100%;text-align:center">
                                    <el-image  style="width:70px;height: 70px" :src="item[1].menu_img_url" fit="fill" ></el-image>
                                    <div style="text-align:center">{{item[1].menu_name}}</div>
                                </el-row>
                            </template>

                        </el-col>
                        <el-col :span="8">
                            <template v-if="item.length>2">
                                <el-row @click.native="jump_url(item[2])" class="cursor" type="flex" align="middle" justify="center" style="display: inline-block;width:100%;text-align:center">
                                    <el-image  style="width:70px;height: 70px" :src="item[2].menu_img_url" fit="fill" ></el-image>
                                    <div>{{item[2].menu_name}}</div>
                                </el-row>
                            </template>

                        </el-col>

                    </el-row>
                </template>

            </el-row>
            

        </el-row>
        

    </div>
</template>
<script>
import {
    setItem,
    getItem,
    setCookie,
    getCookie
} from '../../api/tool.js'
import API from '../../api/api';

export default {
    
    data(){
        return{
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            qxt_username:'',//登录账号
            qxt_power_level:'',//权限

            showLevleList:[],//展示的权限的模块数组
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
        //console.log("测试："+sessionStorage.getItem("qxt_session"));
    },
    activated() {
        console.log("主菜单");
        //重置所有数据
        //Object.assign(this.$data, this.$options.data.call(this));
        //this.form_data = this.$options.data().form_data;//单个恢复初始化

        this.getData();//获取数据
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 90);

            this.qxt_username = getItem("qxt_username");//登录账号
            this.qxt_power_level = getItem("power_level");//权限
            if(API.isEmtry(this.qxt_power_level)){
                this.qxt_power_level = getCookie("qxt_power_level");//权限
            }
            console.log("权限getItem:"+this.qxt_power_level);
            
        },
        //获取数据
        getData(){
            var power_levels = this.qxt_power_level.split(",");//权限 数组
            var p_list = [];
            for (var i = 0; i < power_levels.length; i++) {
                var power_level = power_levels[i];//
                if(power_level == 66){
                    var menu_img_url = require("@/assets/img/menu/yonghu.png");//菜单图片地址
                    var menu_name = "国际客户管理";//菜单名称
                    var menu_url = "/inter_user_list";//菜单上的访问地址

                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url:menu_url};
                    p_list.push(obj);
                }
                if(power_level == 66){
                    var menu_img_url = require("@/assets/img/menu/shujutongji.png");//菜单图片地址
                    var menu_name = "国际统计";   //菜单名称
                    var menu_url = "/inter_count_list";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url:menu_url};
                    p_list.push(obj);
                }
               if(power_level == 66){
                    var menu_img_url = require("@/assets/img/menu/haoduan.png");//菜单图片地址
                    var menu_name = "国际下行查询";   //菜单名称
                    var menu_url = "/inter_mt_list";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url:menu_url};
                    p_list.push(obj);
                }
            }
            this.showLevleList = [];
            var showLevleList2 = new Array();
            for (var i = 0,len=p_list.length; i < len; i++) {
                showLevleList2.push(p_list[i]);
                if(showLevleList2.length == 3 || i == len-1){

                    this.showLevleList.push(showLevleList2);
                    showLevleList2 = new Array();
                }
            }

        },
        //国内
        gn_jump(){
           //路由
            API.router_to("/index");
        },
        //跳转地址
        jump_url(item){
            var menu_url_type = item.menu_url_type;//0:路由  1:跳转地址
            var menu_url = item.menu_url;//菜单上的访问地址
            var menu_name = item.menu_name;//菜单名称

            console.log("跳转地址:"+menu_url);

            API.router_to(menu_url);
            
        },

        //退出
        quit(){
            //跳转到登录页面
            API.userLogin();
        },
    }
};

</script>

<style scoped>

</style>